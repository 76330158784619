@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Back button ============================================================ */
.backButton {
  width: 100px;
  height: 40px;
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.349);
  background-color: rgb(12, 12, 12);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  margin-left: 2px;
}

.IconContainer {
  width: 30px;
  height: 30px;
  background: linear-gradient(to left, #00a922, #5b79f6);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
  color: #ffffff;
  transition-duration: 0.3s;
}

.icon {
  border-radius: 1px;
}

.text {
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1;
  transition-duration: 0.3s;
  font-size: 1.04em;
}

.backButton:hover .IconContainer {
  width: 90px;
  transition-duration: 0.3s;
}

.backButton:hover .text {
  transform: translate(10px);
  width: 0;
  font-size: 0;
  transition-duration: 0.3s;
}

.backButton:active {
  transform: scale(0.95);
  transition-duration: 0.3s;
}


.Collapsible{
   border-radius: 8px;
   background-color: #ffffff;
   box-shadow: 1px 1px 10px 1px rgb(208, 205, 205);
   padding: 20px;
   margin: 10px;
   
}
.Collapsible__contentOuter{
  /* background-color: #fef3f3; */
  display: flex;
}
.Collapsible__contentInner{
}


/* From Uiverse.io by Randdose */ 
/* From Uiverse.io by mrhyddenn */ 
.confirmButton {
  background: transparent;
  position: relative;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgb(40, 144, 241);
  border-radius: 10px;
  outline: none;
  overflow: hidden;
  color: rgb(40, 144, 241);
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
  height: 40px;
}

.confirmButton span {
  margin: 10px;
}

.confirmButton::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: '';
  border-radius: 50%;
  display: block;
  width: 20em;
  height: 20em;
  left: -5em;
  text-align: center;
  transition: box-shadow 0.5s ease-out;
  z-index: -1;
}

.confirmButton:hover {
  color: #fff;
  border: 1px solid rgb(40, 144, 241);
}

.confirmButton:hover::before {
  box-shadow: inset 0 0 0 10em rgb(40, 144, 241);
}
 



/* From Uiverse.io by mRcOol7 */ 
.card {
  --background: linear-gradient(to right, #74ebd5 0%, #acb6e5 100%);
  /* width: 190px;
  height: 254px; */
  padding: 5px;
  border-radius: 1rem;
  overflow: visible;
  background: #74ebd5;
  background: var(--background);
  position: relative;
  z-index: 1;
}

.card::before,
.card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  z-index: -1;
}

.card::before {
  background: linear-gradient(to bottom right, #3fa5f4 0%, #3fa5f4 100%);
  transform: rotate(2deg);
}

.card::after {
  background: linear-gradient(to top right, #84fab0 0%, #8fd3f4 100%);
  transform: rotate(-2deg);
}

.card-info {
  --color: #292b2c;
  background: var(--color);
  color: var(--color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: visible;
  border-radius: 0.7rem;
  position: relative;
  z-index: 2;
}

.card .title {
  font-weight: bold;
  letter-spacing: 0.1em;
}

.card:hover::before,
.card:hover::after {
  opacity: 0;
}

.card:hover .card-info {
  color: #11c07d;
  transition: color 1s;
}




/* From Uiverse.io by alexruix */ 
.loader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
}

.loader:before {
  content: '';
  width: 48px;
  height: 5px;
  background: #3fa5f4;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadow324 0.5s linear infinite;
}

.loader:after {
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #3fa5f4, #11c07d);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump7456 0.5s linear infinite;
}

@keyframes jump7456 {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow324 {

  0%,
    100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}


/* /////// */
/* From Uiverse.io by Creatlydev */ 

:root {
  --clr: #00ad5a;
}

.payButton {
  background-color: var(--clr);
  text-decoration: none;
  line-height: 1;
  border-radius: 0.3rem;
  overflow: hidden;
  position: relative;
  box-shadow: 10px 10px 20px rgba(0,0,0,.05);
  background-color: #fff;
  color: #121212;
  border: none;
  cursor: pointer;
}

.buttonDecor {
  position: absolute;
  inset: 0;
  background-color: var(--clr);
  transform: translateX(-100%);
  transition: transform .3s;
  z-index: 0;
}

.buttonContent {
  display: flex;
  align-items: center;
  font-weight: 600;
  position: relative;
  overflow: hidden;
}

.buttonIcon {
  width: 48px;
  height: 40px;
  background-color: var(--clr);
  display: grid;
  place-items: center;
}

.buttonText {
  display: inline-block;
  transition: color .2s;
  padding: 2px 1.5rem 2px;
  padding-left: .75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.payButton:hover .buttonText {
  color: #fff;
}

.payButton:hover .buttonDecor {
  transform: translate(0);
}




/* /////// */
/* From Uiverse.io by vinodjangid07 */ 
.deleteButton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: .3s;
  overflow: hidden;
  position: relative;
}

.svgIcon {
  width: 10px;
  transition-duration: .3s;
}

.svgIcon path {
  fill: white;
}

.deleteButton:hover {
  width: 100px;
  height: 40px;
  border-radius: 50px;
  transition-duration: .3s;
  background-color: rgb(255, 69, 69);
  align-items: center;
}

.deleteButton:hover .svgIcon {
  width: 30px;
  transition-duration: .3s;
  transform: translateY(60%);
}

.deleteButton::before {
  position: absolute;
  top: -25px;
  content: "Delete";
  color: white;
  transition-duration: .3s;
  font-size: 2px;
}

.deleteButton:hover::before {
  font-size: 13px;
  opacity: 1;
  transform: translateY(30px);
  transition-duration: .3s;
}



/* //////// */

/* Basic styles for the container */
.itemContainer {
  color: white;
  text-align: center;
  /* line-height: 200px; */
  /* margin: 20px; */
  overflow: hidden;
  transform: translateY(-100%); /* Start above the screen */
  transition: transform 0.5s ease-out, opacity 0.5s ease-out; /* Transition for sliding and fading */
  opacity: 0; /* Initially invisible */
  visibility: hidden; /* Ensure it's not interactable while hidden */
}

/* Slide-down animation (when container is visible) */
.itemContainer.slide-down {
  transform: translateY(0); /* Move to visible position */
  opacity: 1; /* Make the container visible */
  visibility: visible; /* Allow interaction */
}

/* Slide-up animation (when container is hidden) */
.itemContainer.slide-up {
  transform: translateY(-100%); /* Move out of view */
  opacity: 0; /* Fade out */
  visibility: hidden; /* Make it non-interactable */
}



/* /////////////////////// */
/* From Uiverse.io by Yaya12085 */ 
.radio-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-inputs > * {
  margin: 6px;
}

.radio-input:checked + .radio-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #2260ff;
}

.radio-input:checked + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #2260ff;
  border-color: #2260ff;
}

.radio-input:checked + .radio-tile .radio-icon svg {
  fill: #2260ff;
}

.radio-input:checked + .radio-tile .radio-label {
  color: #2260ff;
}

.radio-input:focus + .radio-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}

.radio-input:focus + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
}

.radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-height: 80px;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}

.radio-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
}

.radio-tile:hover {
  border-color: #2260ff;
}

.radio-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.radio-icon svg {
  width: 2rem;
  height: 2rem;
  fill: #494949;
}

.radio-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
  font-size: 13px;
}

.radio-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}



/* From Uiverse.io by Smit-Prajapati */ 
/* From Uiverse.io by vinodjangid07 */ 
.s_card {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 25px;
  gap: 20px;
  
}

/* for all social containers*/
.socialContainer {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition-duration: .3s;
}
/* instagram*/
.containerOne:hover {
  background: linear-gradient(to right, #11c07d, #3fa5f4);
  border-radius: 50%;
  transition-duration: .3s;
}
/* twitter*/
.containerTwo:hover {
  background: linear-gradient(to right, #11c07d, #3fa5f4);
  border-radius: 50%;
  transition-duration: .3s;
}
/* linkdin*/
.containerThree:hover {
  background: linear-gradient(to right,  #11c07d, #3fa5f4);
  border-radius: 50%;
  transition-duration: .3s;
}
/* Whatsapp*/
.containerFour:hover {
  background: linear-gradient(to right, #11c07d, #3fa5f4);
  border-radius: 50%;
  transition-duration: .3s;
}

.socialContainer:active {
  transform: scale(0.9);
  transition-duration: .3s;
}

.socialSvg {
  width: 17px;
}

.socialSvg path {
  fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
  animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}









 